<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-forgot-on': this.state == 'forgot'
      }"
      id="kt_login"
    >
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
              @submit.stop.prevent="onSubmitLogin()"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Willkommen bei Dabiri
                </h3>
                <span class="text-muted font-weight-bold font-size-h4"
                  >Neu hier?
                  <router-link
                    to="/register"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                  >
                    <a class="text-primary font-weight-bolder" @click="navigate"
                      >Benutzerkonto erstellen</a
                    >
                  </router-link>
                </span>
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >Email</label
                >
                <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  <b-form-input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="text"
                    name="email"
                    ref="email"
                    v-model="$v.signin_form.email.$model"
                    :state="validateSigninState('email')"
                    aria-describedby="input-signin_email-feedback"
                  />
                  <b-form-invalid-feedback id="input-signin_email-feedback">
                    Das ist ein Pflichtfeld und muss eine gültige Email Adresse
                    sein.
                  </b-form-invalid-feedback>
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Passwort</label
                  >
                  <a
                    class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                    id="kt_login_forgot"
                    @click="showForm('forgot')"
                    >Password vergessen?</a
                  >
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                >
                  <b-form-input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="password"
                    name="password"
                    ref="password"
                    v-model="$v.signin_form.password.$model"
                    :state="validateSigninState('password')"
                    autocomplete="off"
                    aria-describedby="input-password-feedback"
                  />
                  <b-form-invalid-feedback id="input-password-feedback">
                    Das ist ein Pflichtfeld.
                  </b-form-invalid-feedback>
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                  ref="kt_login_signin_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                >
                  Login
                </button>
                <!--                <button-->
                <!--                  type="button"-->
                <!--                  class="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-lg"-->
                <!--                >-->
                <!--                  <span class="svg-icon svg-icon-md">-->
                <!--                    <inline-svg-->
                <!--                      src="media/svg/social-icons/google.svg"-->
                <!--                    /> </span-->
                <!--                  >Sign in with Google-->
                <!--                </button>-->
              </div>
            </form>
          </div>
          <!--end::Signin-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_form"
              @submit.stop.prevent="onSubmitForgot()"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Forgotten Password ?
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Enter your email to reset your password
                </p>
              </div>
              <div class="form-group">
                <b-form-input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                  type="email"
                  name="email"
                  placeholder="Email"
                  v-model="$v.forgot_form.email.$model"
                  :state="validateForgotState('email')"
                  autocomplete="off"
                  aria-describedby="input-email-feedback"
                />
                <b-form-invalid-feedback id="input-email-feedback">
                  Das ist ein Pflichtfeld und muss eine gültige Email Adresse
                  sein.
                </b-form-invalid-feedback>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  type="submit"
                  ref="kt_login_forgot_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                >
                  Senden
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  Abbrechen
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import KTUtil from "@/assets/js/components/util";
import { mapState } from "vuex";
import { LOGIN, RESET_AUTH, RESET_PASSWORD } from "@/store/auth.module";
import Swal from "sweetalert2";
import { alertMixin } from "@/mixins/alertMixin";
import { reCaptchaMixin } from "@/mixins/reCaptchaMixin";

export default {
  mixins: [validationMixin, alertMixin, reCaptchaMixin],
  name: "login",
  data() {
    return {
      state: "signin",
      // Remove this dummy login info
      signin_form: {
        email: "thomas-nadig@bluewin.ch",
        password: "dabiriAPI"
      },
      forgot_form: {
        email: ""
      }
    };
  },
  validations: {
    signin_form: {
      email: {
        required,
        email
      },
      password: {
        required
      }
    },
    forgot_form: {
      email: {
        required,
        email
      }
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  },
  async mounted() {
    await this.$recaptchaLoaded();
    this.$recaptchaInstance.showBadge();
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },
    validateSigninState(name) {
      const { $dirty, $error } = this.$v.signin_form[name];
      return $dirty ? !$error : null;
    },
    async onSubmitLogin() {
      this.$v.signin_form.$touch();
      if (this.$v.signin_form.$anyError) {
        this.showCheckProvidedDataAlert();
        return;
      }

      // Check reCaptcha
      const isRecaptchaValid = await this.isReCaptchaValid();
      if (!isRecaptchaValid) {
        this.showReCaptchaError();
        return;
      }

      // clear existing errors
      await this.$store.dispatch(RESET_AUTH);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send login request
        this.$store
          .dispatch(LOGIN, this.signin_form)
          // go to which page after successfully login
          .then(() => {
            this.$router.push({ name: "dashboard" });
          })
          .catch(() => {
            // console.log("Login error: ", error);
            Swal.fire({
              title: "",
              text:
                "Kein aktives Konto mit den angegebenen Anmeldedaten gefunden.",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-secondary"
              },
              heightAuto: false
            });
          });
        // .catch(() => {});

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },
    validateForgotState(name) {
      const { $dirty, $error } = this.$v.forgot_form[name];
      return $dirty ? !$error : null;
    },
    async onSubmitForgot() {
      this.$v.forgot_form.$touch();
      if (this.$v.forgot_form.$anyError) {
        this.showCheckProvidedDataAlert();
        return;
      }

      // Check reCaptcha
      const isRecaptchaValid = await this.isReCaptchaValid();
      if (!isRecaptchaValid) {
        this.showReCaptchaError();
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_forgot_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send login request
        this.$store
          .dispatch(RESET_PASSWORD, this.forgot_form)
          .then(() => {
            // console.log(response);
            Swal.fire({
              title: "",
              text: "Die E-Mail zum Zurücksetzen des Passworts wurde gesendet.",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-secondary"
              },
              willClose: () => {
                this.$router.push({ name: "login" });
              }
            });
          })
          .catch(() => {
            // console.error(error);
            this.showCheckProvidedDataAlert();
          });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    }
  }
};
</script>
