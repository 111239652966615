import ApiService from "@/services/api.service";

export const reCaptchaMixin = {
  methods: {
    async isReCaptchaValid() {
      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha("login");
      return new Promise((resolve, reject) => {
        ApiService.post("verify-recaptcha", { token: token })
          .then(response => {
            // console.log("RECAPTCHA: " + response.data.is_human);
            resolve(response.data.is_human);
          })
          .catch(() => {
            reject(false);
          });
      });
    }
  }
};
